import './header.css'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import logo from './images/hefner-monogram.svg'

export default function Header(){
    return (
        <Navbar bg="primary" variant="dark" className="header">
        <Container>
        <Navbar.Brand href="/">
      <img
        src={logo}
        width="50"
        height="50"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
      />
    </Navbar.Brand>        
        <Nav className="me-auto">
          <NavDropdown title="Report Automation" id="basic-nav-dropdown">
            <NavDropdown.Item href="/etl">Etls</NavDropdown.Item>
            <NavDropdown.Item href="/report-automation">Report-Automation</NavDropdown.Item>
            <NavDropdown.Item href="/validate">Validate Report Ingestion</NavDropdown.Item>
            <NavDropdown.Item href="/artificialstreamfees">Apply artificial stream fees</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Finance Tools" id="basic-nav-dropdown">
            <NavDropdown.Item href="/artistdebt">Artist-debt</NavDropdown.Item>
            <NavDropdown.Item href="/moss">Moss</NavDropdown.Item>
            <NavDropdown.Item href="/periodic-summary">Periodic summary</NavDropdown.Item>
            <NavDropdown.Item href="/spotify-invoice-reports">Spotify Invoice Reports</NavDropdown.Item>
            <NavDropdown.Item href="/apple-stream-counts">Apple Stream Counts</NavDropdown.Item>
        </NavDropdown>
        </Nav>
        </Container>
      </Navbar>
        )
}