import { Link } from 'react-router-dom'
import {Row, Col, Card, Container} from 'react-bootstrap'
import './'

export default function Landing(){
    return(
        <Container fluid="true">
            <Link to="/"/>
            <Row>
            <Col>
            <Card>
            <Card.Body>
                <Card.Title><h5>Automation of report ingestion</h5></Card.Title>    
                <Card.Subtitle>Step 1. Report Transformation to RU format</Card.Subtitle>            
                <Card.Text>
                <Col>
                    <Link to="/etl">
                        <Card bg="info" key="1" text= 'dark' style={{ width: '100%' }} className="md-3">
                            <Card.Body>
                                <Card.Title>ETL Services</Card.Title>
                                <Card.Text>
                                All services related to report transformations from DSP format
                                                                to Record Union format
                                </Card.Text>
                            </Card.Body>
                        </Card>        
                    </Link>
                </Col>
                
                </Card.Text>

                <Card.Subtitle>Step 2. Automated Report Ingestion</Card.Subtitle>            
                <Card.Text>
                <Col>

                    <Link to="/report-automation">
                    <Card bg="info" key="1" text= 'dark' style={{ width: '100%' }} className="md-2">
                        <Card.Body>
                        <Card.Title>Report Automation</Card.Title>
                        <Card.Text>
                            Automatically ingest single report or all the reports of the selected month
                        </Card.Text>
                        </Card.Body>
                    </Card>    

                    </Link>
                </Col>
                
                </Card.Text>

                <Card.Subtitle>Step 3. Report Validation</Card.Subtitle>            
                <Card.Text>
                <Col>

                    <Link to="/validate">
                    <Card bg="info" key="1" text= 'dark' style={{ width: '100%' }} className="md-2">
                        <Card.Body>
                        <Card.Title>Validate reports</Card.Title>
                        <Card.Text>
                            Validation of imported royalty reports
                        </Card.Text>
                        </Card.Body>
                    </Card>    

                    </Link>
                </Col>
                                    
                </Card.Text>

                <Card.Subtitle>Step 4. Artificial stream fee application</Card.Subtitle>            
                <Card.Text>
                <Col>

                    <Link to="/artificialstreamfees">
                    <Card bg="info" key="1" text= 'dark' style={{ width: '100%' }} className="md-2">
                        <Card.Body>
                        <Card.Title>Artificial stream fees</Card.Title>
                        <Card.Text>
                            Application of artificial stream fees
                        </Card.Text>
                        </Card.Body>
                    </Card>    

                    </Link>
                </Col>
                                    
                </Card.Text>
            </Card.Body>
            </ Card>
            </Col>

            <Col>
            <Card>
                <Card.Body>
                    <Card.Title><h5>Finance Tools</h5></Card.Title>    
                    <Card.Text>
                        <Col>
                            <Link to="/moss">
                                <Card bg="info" key="1" text= 'dark' style={{ width: '100%' }} className="md-2">
                                    <Card.Body>
                                    <Card.Title>Moss Report</Card.Title>
                                    <Card.Text>
                                        Quarterly report run for Financial purposes
                                    </Card.Text>
                                    </Card.Body>
                                </Card>    
                        
                            </Link>
                        </Col>
                        < br />
                        <Col>
                            <Link to="/artistdebt">
                                <Card bg="info" key="1" text= 'dark' style={{ width: '100%' }} className="md-2">
                                    <Card.Body>
                                    <Card.Title>Artist debt</Card.Title>
                                    <Card.Text>
                                        Calculation of artist debt on a specified date
                                    </Card.Text>
                                    </Card.Body>
                                </Card>    
                            </Link>
                        </Col>
                        < br />
                        <Col>
                            <Link to="/periodic-summary">
                                <Card bg="info" key="1" text= 'dark' style={{ width: '100%' }} className="md-2">
                                    <Card.Body>
                                    <Card.Title>Periodic summary</Card.Title>
                                    <Card.Text>
                                        Quarterly report for the Swedish tax office
                                    </Card.Text>
                                    </Card.Body>
                                </Card>    
                            </Link>
                        </Col>
                        < br />
                        <Col>
                            <Link to="/spotify-invoice-reports">
                                <Card bg="info" key="1" text= 'dark' style={{ width: '100%' }} className="md-2">
                                    <Card.Body>
                                    <Card.Title>Spotify Invoice Reports</Card.Title>
                                    <Card.Text>
                                        Monthly invoice reports from Spotify
                                    </Card.Text>
                                    </Card.Body>
                                </Card>    
                            </Link>
                        </Col>
                        < br />
                        <Col>
                            <Link to="/apple-stream-counts">
                                <Card bg="info" key="1" text= 'dark' style={{ width: '100%' }} className="md-2">
                                    <Card.Body>
                                    <Card.Title>Apple Stream Counts</Card.Title>
                                    <Card.Text>
                                        Load monthly Apple user stream-counts
                                    </Card.Text>
                                    </Card.Body>
                                </Card>    
                            </Link>
                        </Col>
                    </Card.Text>
                </Card.Body>
            </Card>
            </Col>



            </Row>
        </Container>


    )
}