import './appleStreamCountsResponse.css';
import {Container, Row, Col} from 'react-bootstrap'

export default function AppleStreamCountsResponse(props) {

    if ( props.data.isError ){
        console.log("isError")
        console.log(props)
        return(
            <div className="response error">
                <b>{props.data.errorMessage}</b>
            </div>
            )
    }
    
    if ( props.data.date ) {
        console.log("data:")
        console.log(props.data)
    
        return(
            <div>
                <Container>
                    <Row>
                        <Col span="2" style={{display:'flex', justifyContent:'center'}}><h1>Apple stream-counts data loaded for {props.data.date}:</h1></Col>
                    </Row>
                    <Row>
                        <Col style={{display:'flex', justifyContent:'right'}}><h1>Total no stream-counts loaded:</h1></Col>
                        <Col><h1>{props.data.rowCount}</h1></Col>
                    </Row>
                </Container>
            </div>
        )
    }
    else{
        console.log(props)
        return(<div className="spinner-border text-primary" role="status">
        <span className="sr-only"></span>
      </div>)
    }
}